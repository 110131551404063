var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"69bb10bbfa149e80b64af3f5c76f26703b271c9f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { ExtraErrorData } from "@sentry/integrations";
import { init } from "@sentry/nextjs";
import { dropUndefinedKeys } from "@sentry/utils";
import LogRocket from "logrocket";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn:
    SENTRY_DSN ||
    "https://9e9ca676e394437098b6bd35f086e8bb@o546899.ingest.sentry.io/5889914",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  beforeSend(event, hint) {
    const newExtra = event.extra ?? {};

    const logRocketSession = LogRocket?.sessionURL;

    if (logRocketSession) {
      // eslint-disable-next-line no-param-reassign
      newExtra.LogRocket = logRocketSession;
    }

    if (hint?.originalException?.isAxiosError) {
      const lookupId =
        hint?.originalException?.config?.headers?.["x-lookup-id"];

      if (lookupId) {
        // eslint-disable-next-line no-param-reassign
        newExtra.lookupId = lookupId;
      }

      // Remove undefined keys from Axios error
      // eslint-disable-next-line no-param-reassign
      event.contexts = dropUndefinedKeys(event.contexts);
    }

    // eslint-disable-next-line no-param-reassign
    event.extra = newExtra;

    return event;
  },
  integrations: [new ExtraErrorData()],
});
